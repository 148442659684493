import { getClient } from "src/api/api.ts";
import { components } from "src/api/schema";
import { ContainerFilterOptions, ContainerFilterProps, ContainerSortBy } from "src/model/container.ts";
import { PageAndSortRequest, SortRequest } from "src/model/pageRequest.ts";
import { PaginatedResponse } from "src/model/paginatedResponse.ts";
import { ReportRequest } from "src/model/reportRequest.ts";
import { Container } from "src/model/subscription.ts";
import { addOneDay } from "src/utils/datetime.ts";

const mapFilter = (filter: ContainerFilterProps): components["schemas"]["ContainerFilterIn"] => {
  return {
    query: filter.query,
    companyId: filter.companyId,
    customerId: filter.customerId,
    scacs: filter.scacs,
    portOfDischargeCodes: filter.portOfDischargeCodes,
    portOfLoadingCodes: filter.portOfLoadingCodes,
    etaPortOfDischargeFrom: filter.etaPortOfDischarge?.at(0)?.getTime(),
    etaPortOfDischargeTo: addOneDay(filter.etaPortOfDischarge?.at(1)?.getTime()),
    status: filter.status,
  };
};

export const fetchContainers = async (
  pagination: PageAndSortRequest<ContainerSortBy>,
  filter: ContainerFilterProps
): Promise<PaginatedResponse<Container>> => {
  const { data, error } = await getClient().POST("/API/iSEA5/V2.1/Containers/Search", {
    params: {
      query: {
        limit: pagination.limit,
        offset: pagination.offset,
        sortBy: pagination.sortBy,
        sortDir: pagination.sortDir,
      },
    },
    body: mapFilter(filter),
  });
  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch containers");
};

export const fetchContainerFilterOptions = async (filter: ContainerFilterProps): Promise<ContainerFilterOptions> => {
  const { data, error } = await getClient().POST("/API/iSEA5/V2.1/Containers/FilterOptions", {
    body: mapFilter(filter),
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch container filter");
};

export const fetchContainerReportRequest = async (
  filter: ContainerFilterProps,
  sorting: SortRequest<ContainerSortBy>
): Promise<ReportRequest> => {
  const { data, error } = await getClient().POST("/API/iSEA5/V2.1/Containers/Reports", {
    params: {
      query: {
        sortBy: sorting.sortBy,
        sortDir: sorting.sortDir,
      },
    },
    body: mapFilter(filter),
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch container report request");
};
