import { getClient } from "src/api/api.ts";
import { HttpError } from "src/api/httpError.ts";
import { PageRequest } from "src/model/pageRequest.ts";
import { PaginatedResponse } from "src/model/paginatedResponse.ts";
import { User, UserPreferences, UserWithPreferences } from "src/model/user.ts";
import { UserSaveRequest } from "src/model/userSaveRequest.ts";

export interface Auth {
  accessToken: string;
  refreshToken: string;
}

export const login = async (username: string, password: string): Promise<Auth> => {
  const { data, error } = await getClient().POST("/API/iSEA5/V2.1/Login", {
    body: {
      username: username,
      password: password,
    },
  });

  if (data !== undefined) {
    return data;
  }
  if (error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  } else {
    throw new HttpError(error.statusCode, error.message);
  }
};

export const refreshToken = async (refreshToken: string): Promise<Auth> => {
  const { data, error } = await getClient().POST("/API/iSEA5/V2.1/RefreshAccessToken", {
    body: {
      refreshToken: refreshToken,
    },
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined) {
    if (error.statusCode === 503 && error.message !== null) {
      throw new Error(error.message);
    } else {
      throw new HttpError(error.statusCode, error.message);
    }
  }
  throw new Error("Failed to fetch access token");
};

export const requestPasswordReset = async (mail: string): Promise<void> => {
  await getClient().POST("/API/iSEA5/V2.1/Users/PasswordResetRequest", {
    body: {
      mail: mail,
    },
  });
};

export const changePassword = async (token: string, password: string): Promise<void> => {
  await getClient().POST("/API/iSEA5/V2.1/Users/PasswordReset", {
    body: {
      passwordChangeToken: token,
      password: password,
    },
  });
};

export const fetchUsers = async (pagination: PageRequest): Promise<PaginatedResponse<User>> => {
  const { data, error } = await getClient().GET("/API/iSEA5/V2.1/Users", {
    params: {
      query: {
        limit: pagination.limit,
        offset: pagination.offset,
      },
    },
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch users");
};

export const fetchUserDetails = async (userId: number): Promise<User> => {
  const { data, error } = await getClient().GET("/API/iSEA5/V2.1/Users/{userId}", {
    params: {
      path: {
        userId: userId,
      },
    },
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch user details");
};

export const createUser = async (userSaveRequest: UserSaveRequest): Promise<User> => {
  const { data, error } = await getClient().POST("/API/iSEA5/V2.1/Users", {
    body: userSaveRequest,
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to create user");
};

export const updateUser = async (userId: number, userSaveRequest: UserSaveRequest): Promise<User> => {
  const { data, error } = await getClient().PUT("/API/iSEA5/V2.1/Users/{userId}", {
    params: {
      path: {
        userId: userId,
      },
    },
    body: userSaveRequest,
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to update user");
};

export const deleteUser = async (userId: number) => {
  await getClient().DELETE("/API/iSEA5/V2.1/Users/{userId}", {
    params: {
      path: {
        userId: userId,
      },
    },
  });
};

export const fetchOwnUserDetails = async (): Promise<UserWithPreferences> => {
  const { data, error } = await getClient().GET("/API/iSEA5/V2.1/Users/Me");
  if (data !== undefined) {
    return { ...data, preferences: data.preferences !== null ? JSON.parse(data.preferences) : null };
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch user preferences");
};

export const updatePreferences = async (preferences: UserPreferences) => {
  const { data, error } = await getClient().POST("/API/iSEA5/V2.1/Users/Me/Preferences", {
    body: {
      preferences: JSON.stringify(preferences),
    },
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch user preferences");
};
