import { FC, useActionState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { requestPasswordReset } from "src/api/user.ts";

export const PasswordResetPage: FC = () => {
  const onSubmit = async (_: boolean, loginFormData: FormData): Promise<boolean> => {
    try {
      const email = loginFormData.get("email") as string;
      await requestPasswordReset(email);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  };

  const [isRequested, formAction, isPending] = useActionState(onSubmit, false);

  return (
    <>
      <Helmet>
        <title>iSEA Trace - Reset Password</title>
      </Helmet>
      <Row>
        <Col xs={12} sm={8} md={6} xl={4} className="m-auto mt-5">
          <div className="bg-white shadow p-3">
            <Form action={formAction}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label column="sm">Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" name="email" />
              </Form.Group>

              <div className="d-flex align-items-center justify-content-between">
                <div>{isRequested ? "You will receive an email with further instructions." : null}</div>
                <Button variant="primary" type="submit" disabled={isPending || isRequested}>
                  Request new password
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};
