import { getClient } from "src/api/api.ts";
import { ShippingLine } from "src/model/shippingLine.ts";

export const fetchShippingLines = async (): Promise<ShippingLine[]> => {
  const { data, error } = await getClient().GET("/API/iSEA5/V2.1/ShippingLines");
  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch shippinglines");
};
