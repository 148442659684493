import { FC, useActionState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { changePassword } from "src/api/user.ts";
import { useToasts } from "src/store/store.ts";
import { useSearchParams } from "src/utils/searchParams.ts";

const NewPasswordForm: FC<{ token: string }> = ({ token }) => {
  const navigate = useNavigate();
  const { showToast } = useToasts();

  const onSubmit = async (_: null, loginFormData: FormData): Promise<null> => {
    try {
      const password = loginFormData.get("password") as string;
      await changePassword(token, password);
      showToast("Password successfully changed.");
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } catch (error) {
      console.error(error);
    }
    return null;
  };

  const [, formAction, isPending] = useActionState(onSubmit, null);

  return (
    <Form action={formAction}>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label column="sm">Password</Form.Label>
        <Form.Control type="password" placeholder="Password" name="password" />
      </Form.Group>

      <div className="d-flex align-items-center justify-content-between">
        <Button variant="primary" type="submit" disabled={isPending}>
          Reset password
        </Button>
      </div>
    </Form>
  );
};

export const NewPasswordPage: FC = () => {
  const [searchParams] = useSearchParams();
  const tokenParam = searchParams.get("token");

  return (
    <>
      <Helmet>
        <title>iSEA Trace - Reset Password</title>
      </Helmet>
      <Row>
        <Col xs={12} sm={8} md={6} xl={4} className="m-auto mt-5">
          <div className="bg-white shadow p-3">
            {tokenParam === null ? "Missing parameter token" : <NewPasswordForm token={tokenParam} />}
          </div>
        </Col>
      </Row>
    </>
  );
};
